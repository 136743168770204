import Vue from 'vue'
import VueRouter from 'vue-router'
import { configUrl } from './routeUrl'

Vue.use(VueRouter)

const routes = [
  
  {
    path: configUrl('/login'),
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '../Login.vue')
  },
  {
    path: configUrl('/notfound'),
    name: 'notfound',
    component: () => import(/* webpackChunkName: "User" */ '../404.vue')
  },
  {
    path: configUrl('/check'),
    name: 'check',
    component: () => import(/* webpackChunkName: "User" */ '../views/Check/index.vue')
  },
  {
    path: configUrl('/product'),
    name: 'product',
    component: () => import(/* webpackChunkName: "User" */ '../views/Product/index.vue')
  },
  {
    path: configUrl('/task'),
    name: 'task',
    component: () => import(/* webpackChunkName: "User" */ '../views/Task/index.vue')
  },
  {
    path: configUrl('/user'),
    name: 'user',
    component: () => import(/* webpackChunkName: "User" */ '../views/User/index.vue')
  },
  {
    path: configUrl('/order'),
    name: 'order',
    component: () => import(/* webpackChunkName: "User" */ '../views/Order/index.vue')
  },
  { path: '*', redirect: { name: 'user' }},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  if (to.name == 'notfound' || to.name == 'login') {
    if (token) {
      next({
        replace: true,
        path: configUrl('/check')
      })
    }
    next()
  } else {
    if (token) {
      next()
    } else {
      next({
        replace: true,
        path: configUrl('/login')
      })
    }
  }
  
})

export default router
