<template>
  <div id="app">
    <el-row class="tac">
      <el-aside v-if="showTab" style="height: 100%;overflow-y: auto;">
        <el-menu
          :default-active="navCurrent"
          background-color="#8aa7ec"
          text-color="#fff"
          active-text-color="#ffd04b"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose">
          <div style="">
            <img src="./assets/farida.png" style="width: 100px; height: 30px;display: block;margin: 30px auto;" alt="" />
          </div>
          <router-link :to="nav.path" v-for="(nav, index) in tabbar" v-bind:key="nav.text">
          <el-menu-item  :index="index+''">
              <i :class="nav.icon" style=""></i>
              <span slot="title">{{nav.text}}</span>
            </el-menu-item>
          </router-link> 
          <el-popconfirm
            title="确定退出登录吗？"
            @confirm="loginout"
            class="tuichulogin"
          >
            <el-button slot="reference" type="text" style="color: #fff;">退出登录</el-button>
          </el-popconfirm>
        </el-menu>
      </el-aside>
      <el-container :style="showTab ?'width: 100%;padding: 12px;overflow-y: auto' : 'width: 100%'">
        <router-view/>
      </el-container>
    </el-row>
    
  </div>
</template>

<script>
import {configUrl} from './router/routeUrl';
export default {
  data() {
    return {
      tabbar: [
        {
          text: '任务审核',
          path: configUrl('/check'),
          name: 'check',
          icon: 'el-icon-edit-outline',
        }, {
          text: '任务管理',
          path: configUrl('/task'),
          name: 'task',
          icon: 'el-icon-date',
        }, {
          text: '商品管理',
          path: configUrl('/product'),
          name: 'product',
          icon: 'el-icon-shopping-bag-2',
        }, {
          text: '订单管理',
          path: configUrl('/order'),
          name: 'order',
          icon: 'el-icon-tickets',
        }, {
          text: '用户管理',
          path: configUrl('/user'),
          name: 'user',
          icon: 'el-icon-user',
        }, 
      ],
      showTab: false,
      navCurrent: '0'
    }
  },
  created() {
    this.routeChange()
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    '$route': 'routeChange'
  },
  
  methods: {
    routeChange() {
      const e = this.$route
      if (e.name == 'notfound' || e.name == 'login') {
        this.showTab = false
      } else {
        this.showTab = true
      }
      this.tabbar.forEach((v, index) => {
        if (v.name == e.name) {
          this.navCurrent = index+''
        }
      })
    },
    handleOpen(e) {
      console.log(e)
    },
    handleClose() {

    },
    loginout() {
      localStorage.removeItem('token')
      this.$message({
        message: '退出登录成功',
        type: 'success',
        onClose: () => {
          localStorage.removeItem('token')
          window.location.href = configUrl('/login')
        }
      });
      
    },
  }
}
</script>


<style>
#app {
  width: 100vw;
  height: 100vh;
}
a:link {
  text-decoration: none;
}

.el-menu-item i {
  color: #fff;
}
.router-link-exact-active .is-active {
  background: #5777a0 !important;
}
.tac {
  display: flex;
  height: 100%;
}
.el-menu-vertical-demo {
  height: 100%;
  position: relative;
}
.tuichulogin {
  position: absolute;
  bottom: 0;
  color: #fff;
  text-align: center;
  right: 0;
  width: 100%;
  line-height: 60px;
  background: #8aa7ec;
}
</style>
